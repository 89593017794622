.container {
    max-width: 50vw;
    margin: 0 auto;
    padding: 5vh;
}

.stories {
    align-items: center;
    font-family: "Inter", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.story {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.storyImg {
    width: 100%;
    height: 45vh;
    object-fit: cover;
    cursor: pointer;
}

.storyInfo {
    flex: 1;
    padding-top: 30px;
    width: 85%;
    margin: 0 auto;
}

.storyMetadata {
    padding-bottom: 15px;
    font-size: 11px;
    font-weight: 600;
    color: #aaa;
}

.spacer {
    margin: 0 8px;
}

.storyTitle {
    font-size: 1.6vw;
    font-weight: 800;
    cursor: pointer;
    color: #15171a;
}

.storyTitle:hover {
    color: #404040;

}

.storyText {
    font-weight: 300;
    color: #333;
    line-height: 2.5vh;
}



.storyDate {
    color: #aaa;
}

.storyTag {
    color: #3c45f6;
    cursor: pointer;
}

/* Mobile */ 
@media only screen and (max-width: 768px) {
    .container {
        padding: 3vh;
    }
    .stories {
        padding-top: 1vh;
        margin: 4vw;
    }

    .story {
        width: 100vw;
    }

    .storyImg {
        width: 85vw;
        height: 25vh;
        object-position: center;
    }

    .storyInfo {
        width: auto;
        padding-left: 0%;
    }

    .storyText {
        line-height: 2.8vh;
    }

    .storyTitle {
        font-size: 6vw;
    }
}