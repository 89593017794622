.top {
    width: 100vw;
    height: 10vh;
    font-family: "Inter", sans-serif;
    display: flex;
}

.topLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Just Another Hand';
    font-size: 2.5vw;
    font-weight: 600;
    letter-spacing: 2px;
    flex: 2;
}

.topLeftLogo:hover {
    color: #434343;
    cursor: pointer;
}

.centerListItem:hover {
    color: #434343;
    cursor: pointer;
}

.topCenter {
    flex: 6;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 35px;
}

.trigger {
    display: none;
}

.btn {
    border-radius: 100px;
    background-color: #3c45f6;
    font-weight: 400;
    font-size: inherit;
    font-family: "Inter", sans-serif;
    color: white;
    border-style: none;
    width: 115px;
    height: 40px;
    cursor: pointer;
}

.btn:hover {
    background-color: #4f57f8;
    animation: ease;
}

.centerList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}


.rightList {
    display: flex;
    align-items: center;
    list-style: none;
}

.centerListItem {
    margin-right: 50px;
    cursor: pointer;
}

.rightListItem {
    margin-left: 30px;
    cursor: pointer;
}

.icon {
    height: auto;
    width: 2em;
}

.icon:hover {
    color: gray;
}

.btn-post {
    border-radius: 100px;
    background-color: #b1b1b1;
    font-weight: 400;
    font-size: inherit;
    font-family: "Inter", sans-serif;
    color: white;
    border-style: none;
    width: 80px;
    height: 40px;
    cursor: pointer;
}

.menuContainer {
    /* Base styles for the container */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh; /* 100% of the viewport height */
    background-color: #3c45f6; /* Yellow color, shade 400 in the default color palette */
    color: white; /* Black text color */
    padding: 1.25rem; /* 1.25rem padding on all sides */
    transform-origin: top;
}

.topElementsContainer {
    display: flex;
    height: 100%;
    width: 90%;
    flex-direction: column; /* Flex container with a column layout */
}

.topElements {
    display: flex;
    justify-content: space-between; /* Space evenly between flex container children */
}

/* Additional styles for the heading */
.heading {
    font-size: 1.125rem; /* Large font size */
}

.close {
    cursor: pointer;
    text-transform: uppercase;
}

.menuLinks {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.menuLink {
    overflow: hidden;
}

.mobileLinkWrapper {
    font-size: 2rem; /* 48px */
    line-height: 1;
    text-transform: uppercase;
    color: white;
}

.mobileLink {
    text-decoration: none;
    color: inherit;
    font-family: "Inter", sans-serif;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .top {
        width: 100vw;
    }

    .topLeft {
        font-size: 7vw;
        flex: 4;
    }

    .topCenter {
        display: none;
    }

    .topRight {
        flex: 4;
    }

    .btn {
        display: none;
    }

    .rightListItem {
        display: none;
    }

    .trigger {
        display: block;
    }
    
}
