.main {
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    text-align: center;
    color: #15171a;
    width: 40vw;
    padding-bottom: 4vh;
    font-size: 2vw;
    font-weight: 800;
}

.img {
    width: 55vw;
    height: 65vh;
    object-fit: cover;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.metadata {
    font-size: 11px;
    font-weight: 600;
    padding-top: 8vh;
    padding-bottom: 2.7vh;
    color: #aaa;
}

.spacer {
    margin: 0 8px;
}

.storyDate {
    color: #aaa;
}

.storyTag {
    color: #3c45f6;
    cursor: pointer;
}

.fullText {
    width: 42vw;
    padding-top: 6vh;
    font-weight: 300;
    color: #333;
    font-family: "Inter", sans-serif;
    line-height: 1.6rem;
}

.paragraph {
    padding-bottom: 0.5vh;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .main {
        width: 100%;
    }

    .img {
        width: 90vw;
        height: 25vh;
        object-position: center;
        margin: 0 auto;
    }

    .info {
        width: auto;
        padding-left: 0%;
    }

    .title {
        font-size: 2.2rem;
        width: 90vw;
        font-weight: 700;
        padding-bottom: 5vh;
    }

    .fullText {
        width: 90%;
        margin: 0 auto;
        line-height: 2.9vh;
    }
}